import React from "react";

const WordCountRatio = () => {
  return (
    <div className="bg-midBlue py-12">
      <h3 className="text-4xl font-bold text-white text-center pb-36">
        Word Count Ratio
      </h3>
      <div className="relative w-11/12 sm:w-7/12 2xl:w-5/12  py-8 mx-auto h-[18rem] 2xl:h-[15rem] bg-white rounded flex flex-col items-center justify-center">
        <div className="flex justify-center">
          <h4 className="absolute -top-8 word-count-heading mx-auto text-center max-w-max border-newGray border-6 text-[18px] sm:text-3xl font-bold text-white bg-darkBlue flex justify-center items-center m-auto px-6 py-4 rounded-full">
            Tomedes Word Count Ratio Tool
          </h4>
        </div>
        <div className="m-auto flex sm:flex-row flex-col justify-between w-9/12 gap-y-6 gap-x-4 pt-16">
          <a
            href="/tools/word-count-ratio/help-myself"
            className="bg-darkBlue text-white py-3 sm:py-2 px-2 rounded-full text-center hover:bg-lightBlue w-full font-bold"
          >
            Help Myself <br /> (It doesn't make you selfish)
          </a>
          <a
            href="/tools/word-count-ratio/help-everybody"
            className="bg-darkBlue text-white py-6 sm:py-2 px-6 rounded-full text-center hover:bg-lightBlue w-full flex justify-center items-center font-bold"
          >
            Help Everybody
          </a>
        </div>
      </div>
    </div>
  );
};

export default WordCountRatio;
