import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const addHelpMyself = (postData) => {
  return http().post(endpoints.forms.helpMyself, postData);
};

export function useHelpMyself() {
  return useMutation(addHelpMyself, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
