import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import Loader from "components/loader";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useHelpMyself } from "hooks/Forms/useHelpMyself";
import { useForm, Controller } from "react-hook-form";
import formCommonPostData from "utils/form-common-post-data";
import { Link } from "gatsby";
import ToolsBreadcrumb from "components/ToolsBredcrumb";

const HelpMyself = () => {
  const { data } = useFetchLanguages();
  const [open, setOpen] = useState(false);
  // const [token, setToken] = useState(false);
  const { status, mutateAsync } = useHelpMyself();
  const [targetWord, setTargetWord] = useState("");

  const captchaRef = useRef(null);
  const {
    register,
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // if (token) {
    const postData1 = {
      source_language: data.source_language.value,
      target_language: data.target_language.value,
      source_word_count: data.source_word_count,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((res) => {
      if (res.success > 0) {
        setTargetWord(res.data);
      } else {
        alert("Wrong Input");
      }
      setOpen(true);
      // reset();
    });
    // } else {
    //   alert("Please verify captcha");
    // }
  };

  // const handleVerify = () => {
  //   setToken(true);
  // };
  const handleReset = () => {
    reset({
      source_language: null,
      target_language: null,
      source_word_count: "",
    });
    setTargetWord("");
  };

  const languageOptions = data?.data?.map(({ source_language }) => ({
    value: source_language,
    label: source_language,
  }));
  const [inputValue, setInputValue] = useState("");
  return (
    <div className="bg-[#F5F5F5] pb-[256px]">
      <div className="w-full  py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="lg:pt-24 pt-12">
        <div className="max-w-7xl mx-auto px-4 ">
          <div className="grid lg:grid-cols-2 gap-16 lg:gap-[120px]">
            <div>
              <h1 className="text-[#5B93FF] font-primary text-center lg:text-start  font-semibold text-base uppercase">
                Word Count Ratio Tool
              </h1>
              <h2 className="text-[28px] lg:text-[64px] text-center lg:text-start  text-[#0A2641] font-primary font-bold leading-[48px] lg:leading-[77px]">
                Find the perfect word count ratio
              </h2>
              <p className="mt-6 text-[#424242] text-center lg:text-start text-lg font-opensans leading-[26px]">
                Select your source language and enter the word count of your
                text. Choose your target language, then click “Calculate Word
                Count” to get an estimated word count. This tool is ideal for
                quickly estimating translation word counts.
              </p>
            </div>
            <div className="">
              <form
                className="m-auto flex flex-col"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="bg-white p-5 rounded-lg shadow-lg">
                  <div className=" w-full">
                    <Controller
                      name="source_language"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={languageOptions}
                          placeholder="Select source language"
                          className=""
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: "#B0B0B0",
                              fontWeight: "normal",
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color: "#0A2641",
                              fontWeight: "600",
                            }),
                            control: (base, state) => ({
                              ...base,
                              borderColor: state.isFocused
                                ? "#0A2641"
                                : base.borderColor,
                              "&:hover": {
                                borderColor: state.isFocused
                                  ? "#0A2641"
                                  : base["&:hover"].borderColor,
                              },
                            }),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className=" w-full mt-2">
                    <input
                      type="text"
                      name="source_word_count"
                      placeholder="Enter word count"
                      className="border-borderGray bg-white font-opensans border rounded text-[#0A2641] font-semibold placeholder:text-[#B0B0B0] placeholder:font-normal  w-full  px-2 py-2"
                      {...register("source_word_count", { required: true })}
                    />
                    {/* <div className="w-full mt-2">
                    <input
                      type="text"
                      name="source_word_count"
                      placeholder="Enter word count"
                      className={`border-borderGray bg-white font-opensans border rounded w-full px-2 py-2 placeholder:text-[#B0B0B0] placeholder:font-normal ${
                        inputValue
                          ? "text-[#0A2641] font-semibold"
                          : "text-[#B0B0B0] font-normal"
                      }`}
                      {...register("source_word_count", { required: true })}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div> */}
                  </div>
                  <div className=" w-full mt-5">
                    <Controller
                      name="target_language"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={languageOptions}
                          className=""
                          placeholder="Select output language"
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: "#B0B0B0",
                              fontWeight: "normal",
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color: "#0A2641",
                              fontWeight: "600",
                            }),
                            control: (base, state) => ({
                              ...base,
                              borderColor: state.isFocused
                                ? "#0A2641"
                                : base.borderColor,
                              "&:hover": {
                                borderColor: state.isFocused
                                  ? "#0A2641"
                                  : base["&:hover"].borderColor,
                              },
                            }),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                {/* <div className="flex sm:flex-row flex-col mx-auto justify-between w-full">
              <label htmlFor="" className="w-[20rem] pb-2 sm:px-5">
                Word count in source document
              </label>
              <input
                type="text"
                name="source_word_count"
                className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3 h-[34px] text-xs mb-6"
                {...register("source_word_count", { required: true })}
              />
            </div> */}
                <div className=" bg-white rounded-lg mt-4 p-5 flex sm:flex-row flex-col gap-8 mx-auto items-center w-full shadow-lg">
                  {/* <div className="mr-[38px]">
                <HCaptcha
                  sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                  onVerify={handleVerify}
                  ref={captchaRef}
                />
              </div> */}
                  {/* <input type="hidden" id="zc_gad" name="zc_gad" value="" /> */}
                  <button
                    type="submit"
                    className="bg-orange w-full text-new max-w-max text-white py-3 px-5  rounded-lg cursor-pointer font-opensans "
                  >
                    Calculate Word Count
                  </button>
                  <div className="w-full">
                    <p
                      htmlFor=""
                      className="text-[#858585] font-opensans text-sm leading-6 font-semibold "
                    >
                      Estimated word count
                    </p>

                    <div className="text-[#858585] w-full mt-4 md:mt-0 font-opensans text-semibold leading-6">
                      {status === "loading" ? (
                        <p className="flex text-[#858585] gap-2 font-opensans text-sm">
                          <Loader />
                          Calculating...
                        </p>
                      ) : targetWord ? (
                        <div className="flex placeholder: justify-between items-center gap-2 w-full">
                          <span className="text-[#27862A] text-lg font-bold leading-6 font-opensans">
                            {targetWord} words
                          </span>
                          <button
                            className="cursor-pointer text-[#0A2641] bg-[#E0EBFF] px-2 py-1 rounded-md font-opensans text-[12px]"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                        </div>
                      ) : (
                        "--"
                      )}
                    </div>
                    {/* <input
                  disabled
                  type="text"
                  value={targetWord}
                  className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3 h-[34px] text-xs mb-6"
                /> */}
                  </div>
                </div>
              </form>
              <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
                Assisted by AI tools. Inaccuracies may occur.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpMyself;
